.center-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
}
.loader {
    width: 68px;
    height: 68px;
    position: relative;
    animation : rotate 4s linear infinite;
  }
  .loader:before,
  .loader:after {
    content:"";
    display: block;
    border: 34px solid;
    border-color: transparent transparent #fff  #fff;
    position: absolute;
    left: 0;
    top: 0;
    animation: mvx 1s infinite ease-in;
  }
  .loader:before {
    left: 0px;
    top: 0px;
    border-color:#003B6E #003B6E transparent transparent;
    animation-name:mvrx;
  }

  @keyframes rotate {
    100% {transform: rotate(360deg)}
  }
  @keyframes mvx {
    0% , 15% {transform: translate(0 , 0) rotate(0deg)}
    50% {transform: translate(-50% , 50%) rotate(180deg)}
    100% {transform: translate(0% , 0%) rotate(180deg)}
  }
  @keyframes mvrx {
    0% , 15%  {transform: translate(0 , 0) rotate(0deg)}
    50% {transform: translate(50% , -50%) rotate(180deg)}
    100% {transform: translate(0% , 0%) rotate(180deg)}
  }
 

