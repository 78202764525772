/**/
.modal-footer .btn-close {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
  background: transparent !important;
}

.modal-footer .btn-close:hover {
  color: #fff !important;
  border-color: #dc3545 !important;
  background: #dc3545 !important;
}

.modal-footer .btn-save {
  color: #28a745 !important;
  border-color: #28a745 !important;
  background: transparent !important;
}

.modal-footer .btn-save:hover {
  color: #fff !important;
  border-color: #28a745 !important;
  background: #28a745 !important;
}

.modal-footer .btn-done {
  color: #28a745 !important;
  border-color: #28a745 !important;
  background: transparent !important;
}

.modal-footer .btn-done:hover {
  color: #fff !important;
  border-color: #28a745 !important;
  background: #28a745 !important;
}

.table-bigboy .img-container {
  width: 180px;
  height: 190px;
  overflow: hidden;
  display: block;
  border-radius: 2px !important;
}

.nft-flex .form-group {
  flex: 0 0 50%;
}
.form-group .disablefields {
  color: red !important;
}

.disablefields input,
.disablefields select {
  background-color: #b9cad794 !important ;
}
.disablefields input::placeholder {
  color: #ffffff89 !important;
}
.disablefields.date-picker-container.form-controll {
  background-color: #b9cad794 !important ;
}
.disablefields.date-picker-container.form-controll input {
  background-color: transparent !important;
}
.disablefields.date-picker-container.form-controll input::placeholder {
  color: #ffffff89 !important;
}
.disablefields.date-picker-container.form-controll i {
  color: #ffffff89 !important ;
}
.disablefields button {
  background-color: #b9cad794 !important ;
  border: 1px solid #b9cad794 !important;
}

.label-font {
  font-weight: 600;
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  vertical-align: top;
  color: #fff;
}

.field-value {
  font-size: 14px;
  line-height: 18px;
}

.modal .modal-content .modal-footer {
  border-top: none;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  justify-content: flex-end;
}

::-webkit-scrollbar {
  width: 6px;
}

nav.navbar.navbar-expand-lg.navbar-light {
  padding-left: 40px;
  padding-right: 40px;
}

.nav ul li.active.nav-item .nav-link .nav-icon-holder,
.nav ul li.active.nav-item .nav-link i.nc-icon.nc-grid-45 {
  color: #fd6f21;
}

.sidebar
  .sidebar-wrapper
  .nav
  .nav-item
  [data-toggle="collapse"]
  ~ div
  .nav
  .nav-item:hover
  .collapsed {
  box-shadow: 0 0 27px rgb(0 0 0 / 5%);
  border-radius: 8px;
  background: #fd6f21 !important;
  position: relative;
  z-index: 99999999;
  color: #f2f2f2;
}

.loaderpai {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Track */
/* ::-webkit-scrollbar-track {box-shadow: inset 0 0 5px grey;} */
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(62 124 177);
}
::-webkit-scrollbar-track {
  background-color: #0d0f17 !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
}

/*scroll*/
.table-responsive::-webkit-scrollbar {
  background-color: #000;
}

.table-responsive::-webkit-scrollbar-button {
  background-color: #0d0f17;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #000;
}

.table-responsive::-webkit-scrollbar-track-piece {
  background-color: #0d0f17;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #fd6f21 !important;
}

.table-responsive::-webkit-scrollbar-corner {
  background-color: #000;
}

.table-responsive::-webkit-resizer {
  background-color: #000;
}

html::-webkit-scrollbar {
  background-color: #000;
}

html::-webkit-scrollbar-button {
  background-color: #0d0f17;
}

html::-webkit-scrollbar-track {
  background-color: #000;
}

html::-webkit-scrollbar-track-piece {
  background-color: #0d0f17;
}

html::-webkit-scrollbar-thumb {
  background: #fd6f21 !important;
}

html::-webkit-scrollbar-corner {
  background-color: #000;
}

html::-webkit-resizer {
  background-color: #000;
}

.sidebar .sidebar-wrapper::-webkit-scrollbar {
  background-color: #000;
}

.sidebar .sidebar-wrapper::-webkit-scrollbar-button {
  background-color: #0d0f17;
}

.sidebar .sidebar-wrapper::-webkit-scrollbar-track {
  background-color: #000;
}

.sidebar .sidebar-wrapper::-webkit-scrollbar-track-piece {
  background-color: #0d0f17;
}

.sidebar .sidebar-wrapper::-webkit-scrollbar-thumb {
  background: #00beff !important;
}

.sidebar .sidebar-wrapper::-webkit-scrollbar-corner {
  background-color: #000;
}

.sidebar .sidebar-wrapper::-webkit-resizer {
  background-color: #000;
}

/**/
body {
  overflow-x: hidden;
  scrollbar-width: thin;
  background-color: #fff7f757;
  /*background-image:url("../images/logo-shape-bg.svg");background-size:80%;background-position: center center;background-repeat:no-repeat;*/
}

.sidebar .sidebar-wrapper {
  scrollbar-width: thin;
}
* {
  scrollbar-color: #003b6e #ccc;
}
.table-responsive {
  scrollbar-width: thin;
}

/* Permissions / Roles */
.check-inline .fancy-checkbox {
  width: 30%;
  font-weight: 700;
  margin-bottom: 10px;
  vertical-align: top;
}

.check-inline label.fancy-checkbox {
  font-weight: normal;
}

.check-inline .fancy-checkbox.selectall-checkbox-holder {
  width: 100%;
}

.search-wrap {
  overflow: hidden;
}

.search-wrap .btn i:first-child {
  margin-right: 0;
}

.search-wrap .search-form {
  margin: 0 10px 10px 0;
  width: 100%;
  max-width: calc(100% - 80px);
}

.label {
  font-weight: normal;
}

.label-info {
  background-color: #43cefa99;
  padding: 2px 5px;
  border-radius: 3px;
}

.label-info span {
  color: #fff;
}

::-webkit-calendar-picker-indicator {
  color: #fff !important;
  filter: invert(1);
}

/**/
.custom-switch .custom-control-label::before {
  border: grey !important;
  background: grey !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #003b6e !important;
  background-color: #003b6e !important;
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::after {
  cursor: pointer;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

input.input-bg.form-control {
  background: transparent;
}

/*pagination*/
.rc-pagination li.rc-pagination-item-active {
  border-color: #00beff !important;
  color: #fff !important;
  background: #00beff !important;
}

.rc-pagination li.rc-pagination-item-active a {
  border-color: transparent !important;
}

.rc-pagination li.rc-pagination-prev button,
.rc-pagination li.rc-pagination-next button {
  border: 1px solid #00beff !important;
  background: transparent !important;
}

.rc-pagination li.rc-pagination-prev button:after,
.rc-pagination li.rc-pagination-next button:after {
  color: #000 !important;
}

.rc-pagination li[aria-disabled="true"]:hover button:after,
.rc-pagination li[aria-disabled="true"]:hover button:after {
  color: #000 !important;
}

.rc-pagination li[aria-disabled="false"]:hover button:after,
.rc-pagination li[aria-disabled="false"]:hover button:after {
  color: #000 !important;
}

.rc-pagination li {
  background: transparent;
  color: #fff;
}

.rc-pagination li a {
  border: 1px solid #00beff;
  color: #000 !important;
}

.rc-pagination li:hover button {
  background-color: transparent !important;
  border-color: #00beff !important;
}

.rc-pagination li.rc-pagination-disabled:hover button::after {
  color: rgba(0, 0, 0, 0.25) !important;
}

.rc-pagination li:hover {
  border-color: #00beff !important;
  color: #fff !important;
  background: #00beff !important;
}

.rc-pagination li:hover a {
  color: #fff;
  border-color: #00beff;
}

/* .rc-pagination li:hover a {
    color: #000;
    border-color: #fff;
} */

/* .rc-pagination li a:hover {
    color: #FB404B !important;
} */

.rc-pagination li.rc-pagination-item-active:hover a {
  color: #000;
}

.rc-pagination li.rc-pagination-item-active a:hover {
  color: #000 !important;
}

.rc-pagination li.rc-pagination-disabled {
  color: #fb404b !important;
  border-color: #fb404b !important;
  background-color: transparent !important;
}

.rc-pagination-disabled .rc-pagination-item-link,
.rc-pagination-disabled:hover .rc-pagination-item-link,
.rc-pagination-disabled:focus .rc-pagination-item-link {
  color: #fb404b !important;
  border-color: #fb404b !important;
  background-color: transparent !important;
  opacity: 1;
}

.rc-pagination li.rc-pagination-disabled:hover button::after {
  color: #000 !important;
}

.rc-pagination li.rc-pagination-disabled:hover button {
  color: #000 !important;
  border-color: #00beff !important;
}

/*header dropdown*/
.dropdown-item.active,
.dropdown-item:active {
  background-color: #3a3a3a;
}

a#dropdown-41471887333 {
  background: #003b6e;
  border-radius: 5px;
  margin-bottom: 2px;
}

a#dropdown-41471887333:hover {
  background: #0474ac;
}

a#dropdown-41471887333 i {
  color: #fff !important;
  font-weight: bold;
}

.dropdown-toggle::after {
  color: #fff !important;
}

.dropdown-menu.show {
  background: #fff;
  box-shadow: 0 0 2px #00beff;
  top: calc(100% + 5px);
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: #0474ac;
  color: #fff;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #0474ac;
  color: #fff;
}

/**/
.sidebar .nav .nav-item.active > .nav-link i {
  color: #fff !important;
}

.triage-select__input {
  color: #fff;
}

/* .css-b8ldur-Input{
    color: #fff !important;
} */
/*ck-editor*/
.ck.ck-editor__main > .ck-editor__editable {
  background: transparent !important;
  border: 1px solid #fff !important;
  color: #fff;
  min-height: 150px;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: #fff !important;
}

.ck.ck-toolbar {
  background: transparent !important;
  border: 1px solid #fff !important;
  color: #000;
  border-bottom: 0 !important;
}

button.ck.ck-button.ck-off {
  color: #fff;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  color: #000;
}

.ck.ck-dropdown .ck-dropdown__panel .ck-list {
  background: transparent !important;
  border: 1px solid #000 !important;
  color: #000;
}

.ck.ck-list__item .ck-button.ck-on:hover:not(.ck-disabled) {
  background-color: #ffda54;
  color: #000;
}

.ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
  background-color: #ffda54;
  color: #000;
}

.ck.ck-list__item .ck-button.ck-on {
  background: rgba(93, 203, 198, 0.2) !important;
  color: #000 !important;
  border: 0;
}

.ck.ck-list__item .ck-button.ck-on:focus {
  box-shadow: none !important;
}

.ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
  background: rgba(93, 203, 198, 0.2) !important;
  color: #000 !important;
}

.ck-rounded-corners
  .ck.ck-dropdown
  .ck-dropdown__panel
  .ck-list
  .ck-list__item:first-child
  .ck-button {
  background: rgba(93, 203, 198, 0.2) !important;
  color: #000 !important;
}

.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):hover,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background-color: rgba(93, 203, 198, 0.2) !important;
  color: #fff !important;
}

button.ck-button_with-text,
button.ck-heading_paragraph {
  color: #000 !important;
}

button.ck-dropdown__button {
  color: #fff !important;
}

/* .ck.ck-toolbar.ck-toolbar_grouping {
    border-bottom: 1px solid #000 !important;
} */

th {
  letter-spacing: 1px;
}

/* .table-bigboy .td-name { min-width: 170px;} */
span.ck-table-bogus-paragraph {
  color: #fff;
}

.btn-filled,
.btn-filled:focus,
.outline-button:active,
.outline-button,
.outline-button:focus,
.outline-button:active {
  /* width: inherit; */
  overflow: hidden;
  padding: auto;
  padding: 10px 20px !important;
  outline: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background: #0474ac;
  border-color: #0474ac;
}

/* .cms-table td,.cms-table th{
    width:190px !important;
} */
.nav-icon-holder {
  display: none;
}

/* .text-truncate {width: 300px !important;} */
option:not(:checked) {
  background-color: #3a3a3a;
  color: #fff !important;
}

select > option:hover {
  background-color: #28a745;
  color: red;
}

select > option:active {
  background-color: #fb404b;
  color: #fff !important;
}

select > option:focus {
  background-color: #fb404b !important;
  color: #fff !important;
}

option:checked,
option:hover:focus {
  background-color: unset;
  color: black !important;
}

select {
  background-color: transparent !important;
  border-radius: 4px;
  color: #000;
  padding: 8px 4px;
  border-color: #9f9292;
}

.form-control:focus {
  background: none !important;
  border: 1px solid #fff;
  box-shadow: none;
  outline: 0 !important;
  color: #fff !important;
}

select.filter-card.select.p-2.d-flex.w-100 {
  background-color: transparent;
  color: #fff;
}

/* table.table-bigboy.table-w{table-layout: fixed;}
.table-bigboy > tbody > tr > td{white-space: normal !important;}
.td-start{width: 50px;
.td-name{width: 480px;word-break: break-all;}
/* .text-truncate {width: 125px;} */
/* .custom-file:focus-visible{box-shadow: none; outline: none !important;} */
/* .faq-title { -webkit-line-clamp: 2; white-space: break-spaces !important;  word-break: break-all !important; width: 480px !important;} */
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: transparent;
}

.faq-title {
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  white-space: break-spaces !important;
  word-break: break-all !important;
  /* width: 480px !important; */
  display: -webkit-box;
  color: #fff;
}

.text-truncate {
  width: 250px !important;
}

label#category-image-label {
  background: transparent;
  color: #fff;
}

label#category-banner-label {
  background: transparent;
  color: #fff;
}

.cetegories-creAted {
  width: 120px;
}

.date-categories {
  width: 120px !important;
}

.custom-file-label::after {
  background-color: transparent !important;
  color: #fff;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: #fff !important;
}

/* icon-input */
input[type="number"] {
  position: relative;
  padding: 0 22px 0 8px;
}

input[type="number"]::-webkit-inner-spin-button {
  position: absolute;
  right: 0;
  top: 0%;
  height: 100%;
  width: 5px;
}

/* table */
.card label {
  font-size: 14px;
}

th.reporting-status {
  min-width: 160px;
}
.text-dangeronly,
.text-dangeronly:hover {
  color: #ef7f86 !important;
}

.collection-image-holder img {
  height: 100%;
}

.rh5v-Overlay_inner {
  background-color: #fb404b;
  right: auto;
  margin-left: 0;
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.rh5v-PlayPause_icon,
.rh5v-Volume_icon,
.rh5v-Fullscreen_icon {
  padding: 0px;
}

.rh5v-DefaultPlayer_component {
  background-color: transparent;
  border-radius: 12px;
  overflow: hidden;
}

.rh5v-DefaultPlayer_video {
  border-radius: 10px;
  display: block;
}

.rh5v-DefaultPlayer_controls {
  bottom: 0;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

/* .card label {cursor: pointer;} */
.card .form-group label {
  font-size: 16px;
}

a.btn.btn-filled.mt-4.w-100.text-white.alert.alert-info {
  width: 250px !important;
}

/*not found*/
.not-found {
  padding: 20px 0 0 0;
  height: 80vh;
}

.not-found h1 {
  font-size: 40px;
  color: #fb404b;
  margin: 0 0 30px;
}

/*basic codes*/
a,
a:hover,
a:focus {
  color: #000;
}

/*react tool tip*/
.__react_component_tooltip.type-dark {
  background-color: #fb404b;
  color: #fff;
  font-weight: bold;
}

.__react_component_tooltip.type-dark.place-bottom:after {
  border-bottom-color: #fb404b;
}

.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: #fb404b;
}

.__react_component_tooltip.type-dark.place-right:after {
  border-right-color: #fb404b;
}

.__react_component_tooltip.type-dark.place-left:after {
  border-left-color: #fb404b;
}

/*buttons*/
.btn-filled,
.btn-filled:focus,
.outline-button:active {
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #003b6e;
  border-radius: 10px;
  overflow: hidden;
  background: #003b6e;
  font-weight: bold;
  color: #fff;
  /* padding: 7px 40px; */
  padding: 7px 20px;
  transition: all ease-in-out 0.3s;
  margin: 5px 0px;
}
.view-partner-form {
  justify-content: center;
  margin-bottom: 20px;
  padding: 10px 30px !important;
}
.view-partner-form span {
  margin: 0 !important;
}
.btn-filled:hover {
  background: #0474ac;
  border-color: #0474ac;
  color: #fff;
}

.btn-outlined {
  font-size: 18px;
  line-height: 24px;
  border-radius: 10px;
  overflow: hidden;
  background-color: transparent;
  border: 1px solid #fff;
  font-weight: bold;
  padding: 7px 40px;
  color: #fff;
}

.outline-button,
.outline-button:focus,
.outline-button:active {
  font-size: 18px;
  line-height: 24px;
  border: 1px solid #003b6e;
  border-radius: 10px;
  overflow: hidden;
  background-color: #003b6e;
  font-weight: 500;
  color: #fff;
  padding: 7px 40px;
  transition: all ease-in-out 0.3s;
}

.outline-button:hover {
  background-color: #0474ac;
  color: #fff !important;
  border-color: #0474ac;
}

/*side bar buttons*/
.sidebar > button,
.main-panel > button,
.sidebar-wrapper > button,
.navbar-fixed > button,
.navbar-minimize > button {
  background: #003b6e;
  border-radius: 10px;
  border: 0.5px solid #003b6e;
  color: #fff;
}

.sidebar > button:hover,
.main-panel > button:hover,
.sidebar-wrapper > button:hover,
.navbar-fixed > button:hover,
.navbar-minimize > button:hover {
  background: #0474ac;
  border-color: #0474ac;
  color: #fff;
  box-shadow: 0 0 7px rgb(4 116 172 / 30%);
}

.sidebar > button:focus,
.sidebar > button:not(:disabled):not(.disabled):active,
.sidebar > button:not(:disabled):not(.disabled):active:focus,
.main-panel > button:focus,
.main-panel > button:not(:disabled):not(.disabled):active,
.main-panel > button:not(:disabled):not(.disabled):active:focus,
.sidebar-wrapper > button:focus,
.sidebar-wrapper > button:not(:disabled):not(.disabled):active,
.sidebar-wrapper > button:not(:disabled):not(.disabled):active:focus,
.navbar-fixed > button:focus,
.navbar-fixed > button:not(:disabled):not(.disabled):active,
.navbar-fixed > button:not(:disabled):not(.disabled):active:focus,
.navbar-minimize > button:focus,
.navbar-minimize > button:not(:disabled):not(.disabled):active,
.navbar-minimize > button:not(:disabled):not(.disabled):active:focus {
  background: #003b6e;
  border-color: #003b6e;
  color: #fff;
  box-shadow: none;
}

/*card*/
.card {
  background: rgba(0, 59, 110, 0.8);
  border-radius: 10px;
  border-radius: 10px;
}

.card.card-stats {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgb(136 144 195 / 20%),
    0 5px 15px 0 rgb(37 44 97 / 15%);
  transition: all ease-in-out 0.3s;
  border: 1px solid #e7ebf4;
  position: relative;
  overflow: hidden;
  z-index: 9;
}
.card-stats.card::before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 170px;
  height: 400px;
  z-index: -1;
  transform: rotate(57deg);
  right: 0px;
  top: -93px;
  border-radius: 35px;
}
.table-bg {
  background-color: #1b1d27;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;
}
.table-bg .table-big-boy.card {
  background: transparent;
  box-shadow: none !important;
}

.card.card-stats:hover {
  box-shadow: 0 0 16px rgba(0, 59, 10, 0.8);
  background: #003b6e;
  transform: scale(1);
}

.card.card-stats:hover .card-category,
.card.card-stats:hover .card-title {
  color: #fff;
}

.card.filter-card {
  background: rgba(0, 59, 110, 0.8);
  border: none;
  border-radius: 10px;
  padding: 0 15px;
  box-shadow: 0 2px 4px 0 rgb(136 144 195 / 20%),
    0 5px 15px 0 rgb(37 44 97 / 15%);
}

.card.filter-card button {
  margin: 0;
  font-weight: 500;
}
.form-group button.outline-button.w-auto.export-btn {
  padding: 10px 23px !important;
}

.card .form-group label {
  color: #fff;
}

.filter-card select {
  color: #fff;
}

.filter-card .form-control {
  border: 1px solid #fff;
  color: #fff;
  min-height: 46px;
}

.filter-btns-holder button {
  width: 25%;
  background-color: #003b6e;
  border: 1px solid #003b6e;
  color: #fff;
}

.filter-btns-holder button:hover {
  background-color: #0474ac;
  border: 1px solid #0474ac;
}
.rdrStartEdge,
.rdrInRange {
  color: rgb(45 90 149) !important;
}

.rdrEndEdge {
  color: rgb(45 90 149) !important;
}
.enter-details-modal .modal-dialog {
  max-width: 750px;
}

.card.filter-card .card-title,
.card.filter-card .card-category,
.card.filter-card label,
.card.card-stats .card-title,
.card.card-stats .card-category {
  color: #1a3c6e;
}

/* .filter-card .form-control{border-color:#000;color:#000;} */

.filter-card .form-control::-webkit-input-placeholder {
  color: #fff;
}

.filter-card .form-control:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.filter-card .form-control::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.filter-card .form-control:-ms-input-placeholder {
  color: #fff;
}

.filter-card .form-control::-ms-input-placeholder {
  color: #fff;
}

.filter-card .form-control::placeholder,
.form-control::placeholder {
  color: #fff !important;
}

.filter-card select {
  border: 1px solid #fff;
  min-height: 46px;
}

/* .card:hover {
    box-shadow: none;
} */

.log-in-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #fff;
  margin-bottom: 12px;
  margin-top: 17px;
  margin-bottom: 0;
}
.login-tagline {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
}
.two-factor-modal p,
.two-factor-modal label,
.two-factor-modal .modal-title,
.two-factor-modal span {
  color: #fff;
}
.date-picker-container.form-controll
  .react-datepicker__view-calendar-icon
  input::placeholder {
  color: #fff;
}
.card .card-header,
.card .card-footer {
  background-color: transparent;
}

.card-header h4,
.card-body label {
  color: #fff !important;
}

.card.card-login .logo-holder {
  width: 180px;
}

.card.card-login .btn-filled,
.card.card-login .btn-filled:focus,
.card.card-login .outline-button:active,
.card.card-login .outline-button,
.card.card-login .outline-button:focus,
.card.card-login .outline-button:active {
  padding: 7px 20px !important;
}

.alert-info {
  background-color: rgba(93, 203, 198, 0.2) !important;
  color: #fff !important;
  font-size: 16px;
  color: #000 !important;
}

.add-icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #393c48;
  color: white;
  border-radius: 100%;
  font-size: 14px;
}
/* .add-icon svg{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #393C48;
    color: white;
    border-radius: 100%;
    font-size: 14px;
} */
.link-btn .add-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 100%;
  font-size: 14px;
  width: 100%;
  background: transparent;
}

.card .card-title {
  color: #fff;
}

/*side bar*/
.sidebar {
  background: rgba(0, 59, 110, 1);
  /* background: rgb(123 199 195 / 78%); */
  width: 290px;
  z-index: 99 !important;
  height: 100vh !important;
  overflow: hidden !important;
  overflow-y: auto !important;
}

.sidebar::after {
  content: "";
  position: fixed;
  z-index: -1;
  width: 290px;
  height: 20%;
  bottom: 0;
  top: auto;
  -webkit-clip-path: polygon(0 23%, 0 0, 100% 100%, 78% 100%);
  clip-path: polygon(0 23%, 0 0, 100% 100%, 78% 100%);
  /* background: transparent linear-gradient(322deg, #41586d 0%, #1f212f00 100%) 0%
    0% no-repeat padding-box; */
  opacity: 0.65;
}

.sidebar .sidebar-wrapper {
  width: 290px;
  padding-bottom: 15px;
}

.main-panel {
  width: calc(100% - 290px);
}

.sidebar .sidebar-wrapper .nav .nav-link p {
  color: inherit;
  font-weight: normal;
  font-size: 14px;
  font-weight: bold;
  white-space: normal;
  line-height: 19px;
  font-size: 13px;
}

.sidebar .logo {
  justify-content: center;
}

.sidebar .logo a.logo-normal {
  width: 170px;
  display: block;
  opacity: 1;
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
  /*content: url("../images/logo.svg");*/
  height: 56px;
}
.sidebar .logo .simple-text img {
  width: 100%;
  height: 100%;
}
.sidebar .logo a.logo-mini {
  display: none;
}
.sidebar-mini .sidebar .logo a.logo-mini {
  display: block;
}
.sidebar-mini .sidebar:hover .logo a.logo-mini {
  display: none;
}

/* .sidebar-mini .sidebar .logo a.logo-normal{content: url("../images/logo-shape.svg");} */
.sidebar-mini .sidebar:hover {
  width: 290px;
}

.sidebar-mini .sidebar:hover .sidebar-wrapper {
  width: 280px;
}

.sidebar-mini .sidebar:hover .logo a.logo-normal {
  /*content: url("../images/logo.svg");*/
  width: 170px;
}

.sidebar .nav .nav-item .nav-link {
  color: #fff;
}

.sidebar .nav .nav-item.active > .nav-link {
  box-shadow: 0 0 27px rgb(0 0 0 / 5%);
  border-radius: 8px;
  background: #0474ac;
  position: relative;
  z-index: 99999999;
  color: #f2f2f2;
}

.sidebar .nav .nav-item:hover > .nav-link {
  box-shadow: 0 0 27px rgb(0 0 0 / 5%);
  border-radius: 8px;
  background: #0474ac;
  position: relative;
  z-index: 99999999;
  color: #fff;
}

.sidebar
  .sidebar-wrapper
  .nav
  .nav-item
  [data-toggle="collapse"]
  ~ div
  .nav
  .nav-item:focus
  .nav-link {
  background: #fd6f21;
  border-radius: 8px;
}

.sidebar
  .sidebar-wrapper
  .nav
  .nav-item
  [data-toggle="collapse"]
  ~ div
  .nav
  .nav-item.active
  .nav-link,
.sidebar
  .sidebar-wrapper
  .nav
  .nav-item
  [data-toggle="collapse"]
  ~ div
  .nav
  .nav-item:hover
  .nav-link {
  background: transparent;
  border-radius: 8px;
}
/* 
.sidebar .sidebar-wrapper .nav .nav-item [data-toggle="collapse"]~div .nav .nav-item.active .nav-link p,
.sidebar .sidebar-wrapper .nav .nav-item [data-toggle="collapse"]~div .nav .nav-item.active .nav-link .nc-icon,
.sidebar .sidebar-wrapper .nav .nav-item [data-toggle="collapse"]~div .nav .nav-item:hover .nav-link p,
.sidebar .sidebar-wrapper .nav .nav-item [data-toggle="collapse"]~div .nav .nav-item:hover .nav-link .nc-icon {
    color: #FD6F21 !important;
} */

.sidebar .nav .nav-item.active > .nav-link .nav-icon-holder {
  background: #003b6e;
  color: #000;
}

.sidebar .nav .nav-item.hover > .nav-link p {
  color: #021761;
}
.sidebar .nav .nav-item .nav-link i {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.sidebar .nav .nav-item:hover > .nav-link p {
  color: #fff !important;
}
.sidebar .nav .nav-link:hover p {
  color: #000;
}

.sidebar .nav .nav-item:hover > .nav-link .nav-icon-holder {
  background: #003b6e;
  color: #000;
}

.sidebar .nav .nav-item:hover > .nav-link i {
  color: #fff !important;
}

.sidebar .nav .nav-item.active > .nav-link p {
  color: #fff !important;
  font-weight: bold;
}

/*stats cards*/
.card-stats .icon-big {
  background: #fff;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 4%);
  /* box-shadow: 0 3px 5px rgb(0 0 0 / 10%); */
}

.card-stats .icon-big i {
  color: #003b6e;
  font-weight: bold;
}

/*table*/
.table > tbody > tr .td-actions .btn {
  transition: all 150ms ease-in;
  border: 1px solid #fff !important;
  margin: 0 5px 0 0;
}
/* .table>tbody>tr:hover td{
    color: inherit;
    border-color: inherit !important;
} */

.table > tbody > tr .td-actions .btn:hover {
  background: #fd6f21 !important;
  border-color: #fd6f21 !important;
  color: #000;
}
.table > tbody > tr .td-actions button:hover:disabled.btn:hover {
  background-color: transparent !important;
  opacity: 0.5;
  border-color: #fff !important;
}
.table .btn-info.btn-link {
  color: #fff;
}

.table .btn-info.btn-link:hover {
  color: #000;
}

/*modals*/
.modal .modal-content {
  background: rgb(12 58 97);
  box-shadow: 0px 0px 10px #000d3c;
}

.modal .modal-content .modal-header h4 {
  color: #fff;
}
.enter-details-btn {
  background: rgb(1 77 142 / 80%);
  border: 1px solid #0474ac;
  color: #fff;
  padding: 11px 17px;
  border-radius: 8px;
}
.enter-details-btn:hover {
  background-color: #0474ac;
  border: 1px solid #0474ac;
  color: white;
}
span.rdrStartEdge.rdrEndEdge {
  color: rgb(45 90 149) !important;
}

.modal .label-font {
  color: #fff;
}

.right-label-checkbox input:checked ~ .checkmark {
  border-color: #fff;
  background-color: transparent;
}

.right-label-checkbox .checkmark:after {
  content: "";
  display: none;
  position: absolute;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  transition: all ease-in-out 0.3s;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.right-label-radio input:checked ~ .checkmark {
  background-color: #003b6e;
  border-color: #fff;
}

.right-label-radio .checkmark:after {
  content: "";
  display: none;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
}

.modal .edit-title label {
  margin-bottom: 0;
}

.swal2-popup .swal2-styled.swal2-confirm {
  color: #fff !important;
}

.swal2-popup .swal2-styled.swal2-cancel {
  border: 1px solid #fff !important;
  background: transparent !important;
}

.ck.ck-dropdown .ck-dropdown__panel {
  background-color: #fff !important;
}

.card-user .avatar:hover {
  box-shadow: 0 0 10px #00beff;
}

.css-6q0nyr-Svg {
  color: #fff;
}

.img-holder {
  width: 40px;
  height: 40px;
}

.img-holder img {
  object-fit: fill;
  padding: 4px;
  width: 100%;
  height: 100%;
}

.card .user-image {
  border-radius: 6px;
}

/*auntions nfts*/
.card.auction-card {
  border-color: #fb404b;
}

.card.auction-card .auction-card-title {
  background: #fb404b;
  color: #fff;
  padding: 10px 10px 6px;
}

/*alert*/
/* .alert-danger {background-color: #FD6F21;color:#000;} */
.alert-danger {
  /* background-color: #FB404B !important; */
  background-color: #63d8f1;
  color: #000;
}

/* .permissions-tags {
    max-height: 32px;
    overflow: hidden;
} */

.badge {
  padding: 7px !important;
}

/*form control*/
.form-control {
  height: 41px;
}

/*footer*/
.footer .copyright copyright a {
  color: #fff;
}
p.copyright.all-right-reserved a,
p.copyright.all-right-reserved {
  color: #7e7e7e;
}
p.copyright.all-right-reserved {
  float: unset;
}
p.copyright.all-right-reserved span {
  color: #000;
}

/*comision icons*/
.c-scale {
  filter: grayscale(1);
}

.d-scale {
  filter: sepia(100%) hue-rotate(190deg) saturate(500%);
}

.cd-scale {
  filter: sepia(98%) saturate(876%) hue-rotate(143deg);
}

/**/
/* .filter-btns-holder button {width: auto;margin: 0 20px 0 0;} */
.filter-btns-holder button {
  /* width: 48%; */
  padding: 7px 12px;
}

/*delete modal*/
.swal2-popup {
  background: #393c48 !important;
  color: #f2f2f2 !important;
  box-shadow: 0 0 10px #393c48;
}

/* .swal2-popup .swal2-title {
    color: rgb(127, 136, 144) !important;
} */
.swal2-popup .swal2-title {
  color: #fff !important;
  padding-top: 44px;
  font-weight: bold !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
  font-size: 18px;
  line-height: 24px;
  border-radius: 10px;
  overflow: hidden;
  background: #fb404b !important;
  border: 1px solid #fb404b !important;
  font-weight: bold;
  padding: 7px 40px;
  color: black;
}

.swal2-popup .swal2-styled.swal2-confirm:hover {
  background: #1b1d27 !important;
  color: #fb404b !important;
  border: 1px solid #fb404b !important;
}

.swal2-popup .swal2-styled.swal2-confirm:active,
.swal2-popup .swal2-styled.swal2-confirm:focus {
  border-color: #fb404b !important;
  color: #fff !important;
  background-color: #fb404b !important;
  box-shadow: none;
}

.swal2-popup .swal2-styled.swal2-cancel {
  /* border: 1px solid #FB404B !important;
    background: #333633 0% 0% no-repeat padding-box !important; */
  font-size: 18px;
  border-radius: 10px;
  color: #fff;
  padding: 7px 40px;
  font-weight: bold;
  transition: all ease-in-out 0.3s;
}

.swal2-popup .swal2-styled.swal2-cancel:hover {
  background-color: #fb404b !important;
  color: #fff !important;
  border: 1px solid #fb404b !important;
}

.swal2-popup .swal2-styled.swal2-cancel:active,
.swal2-popup .swal2-styled.swal2-cancel:focus {
  border-color: #fb404b !important;
  color: #fff !important;
  background-color: #fb404b !important;
  box-shadow: none;
}

/*form control*/
.form-control {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #fff;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  caret-color: white;
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

/*table*/
.table {
  /* table-layout: fixed; */
  width: 100%;
  /* min-width: 864; */
}

.table-responsive {
  overflow-x: auto !important;
}

/*table widths*/
.table .td-start {
  width: 80px;
}

.table .td-image {
  width: 180px;
}

.table .td-mail {
  width: 330px;
}

.table .td-name {
  width: 300px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table tbody .td-name {
  font-weight: 400;
}

.table .td-address {
  width: 600px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table .td-email {
  width: 500px;
}

.table .td-actions {
  width: 140px;
  text-align: center;
}

.table .td-created {
  width: 200px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

/**/
.table .td-permission {
  width: 280px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table .td-status {
  width: 200px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

/**/
.table .td-email {
  width: 400px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table .td-role {
  width: 300px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

/**/
.table .td-description {
  width: 450px;
  padding: 0 10px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

/**/
.table .td-category {
  width: 400px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table .td-owner {
  width: 250px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table .td-price {
  width: 250px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table .td-type {
  width: 250px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table .td-copy {
  width: 250px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

/**/
.table .td-collection {
  width: 400px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

/**/
.table .td-date {
  width: 400px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table .td-time {
  width: 300px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

/**/
.table .td-reported {
  width: 350px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

/**/
.table .td-subject {
  width: 250px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table .td-item {
  width: 400px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

/**/
.table .td-ratings {
  width: 400px;
  white-space: break-spaces;
  word-break: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.table .kyc-badge {
  min-width: 45px;
}

.table .kyc-badge.kyc-status-badge {
  min-width: 90px;
}

.table-bigboy > thead > tr > th {
  text-transform: none;
}

.newsletter-table .td-email-col {
  width: 200px;
}

.acc-tiers-table .img-holder {
  width: 40px;
  height: 40px;
}

.acc-tiers-table .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .dshbrd-user-table .td-name-col,
.dshbrd-user-table .td-email-col,
.kyc-table .td-name-col,
.kyc-table .td-email-col {
    width: 235px;
    white-space: unset;
} */

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-color: rgba(255, 255, 255, 0.3);
}

.table-big-boy .card-body .table tbody td:last-child {
  padding: 8px 10px !important;
}

.card-body label.text-danger {
  color: #ff777f !important;
}
.generic-btn-width {
  max-width: 125px;
  width: 100%;
}
.card-user.table-big-boy.card {
  min-height: 402px;
  padding-top: 100px;
}
.card-user.table-big-boy.big-boy-height.card {
  min-height: 100%;
  margin-bottom: 0;
}
.card-user .recommened-size {
  color: #ff777f;
  font-size: 16px;
  text-align: left;
  padding: 0px 15px;
  margin-bottom: 0;
}
.card-user.custom-recommened-card .card-body {
  min-height: 211px;
}
.staric-lightred {
  color: #ff777f;
}
.full-page.section-image .staric-lightred {
  color: #ff777f !important;
}
.patients-user-wrap .react-datatable #row-0 .dropdown-menu.show {
  inset: -85px auto auto -22px !important;
}
.medical-typeuser-wrap .react-datatable #row-0 .dropdown-menu.show {
  inset: -85px auto auto -22px !important;
}
.patients-user-wrap .react-datatable #row-0 .dropdown-menu .dropdown-item {
  padding: 5px 16px;
}

.input-group-append.append-custom-eye {
  position: absolute;
  top: 37px;
  right: 6px;
}
.input-group-append.append-custom-eye svg {
  color: #fff;
}
/* React Select Styles */
.select-items {
  position: relative;
  z-index: 10;
}
.sidebar .rightcaret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-top: 13px;
  position: absolute;
  right: -12px;
  transition: all 150ms ease-in;
  transform: rotate(275deg);
}
.triage-select__control {
  min-height: 40px !important;
  background: transparent !important;
  border-color: #fff !important;
}

.triage-select__single-value {
  color: #fff !important;
}

.triage-select__control:focus,
.triage-select__control--is-focused {
  box-shadow: none !important;
  border-color: #fff !important;
}

.triage-select__menu-list {
  padding: 0 !important;
  overflow: hidden;
}

.triage-select__menu {
  overflow: hidden !important;
  border-radius: 0 !important;
}

.triage-select__option:hover,
.triage-select__option--is-selected,
.triage-select__option--is-focused {
  background-color: #fb404b !important;
  color: #fff !important;
}

.triage-select__option:hover {
  cursor: pointer !important;
}

.triage-select__placeholder {
  color: #7193c9 !important;
}

.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.9);
}

/* CK Editor Styles */
.ck.ck-labeled-field-view__status {
  color: #fff;
}

.ck.ck-input.ck-input-text:focus {
  box-shadow: none;
}

.ck.ck-label {
  color: #000 !important;
}

.profit-row-buttons {
  padding-top: 7px;
}

.profit-row-buttons button,
.profit-row-buttons button:focus {
  padding: 0 !important;
  width: 46%;
  font-size: 12px;
  border-radius: 6px;
  margin: 0 !important;
}

.doc-holder {
  width: 180px;
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px;
}

.doc-holder img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  border-radius: 4px;
}
.kyc-edit-sec .custom-switch {
  width: 62px;
}
button.btn-link.btn-icon.d-inline-block.align-top.float-none.btn.btn-info span {
  width: 90px;
  display: block;
}

.rdrDateRangePickerWrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 50px;
  top: 258px;
}
.detail-form-check.form-check input[type="checkbox"] {
  opacity: 1;
  visibility: visible;
  transform: scale(1.5);
  border-radius: 50px;
  margin-top: 8px;
}
.navy_blue {
  background-color: rgb(1 83 153 / 80%) !important;
}

.filter-card input[type="checkbox"] {
  transform: scale(1.5);
  margin-right: 6px;
}
.detail-form-check.form-check label {
  color: #fff;
  padding-left: 10px;
}
.detail-form-check.form-check label {
  position: relative;
  padding-left: 32px;
}
.detail-form-check.form-check input {
  display: none;
}
.detail-form-check.form-check label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.detail-form-check.form-check label::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 13px;
  border: 2px solid #ffff;
  left: 8px;
  top: 4px;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
  opacity: 0;
}
.detail-form-check.form-check input:checked + label::after {
  opacity: 1;
}
.detail-form-check.form-check input:checked + label::before {
  background-color: rgb(2 45 82 / 80%);
}
.form-check-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.detail-form-check.form-check {
  margin-right: 0px;
  padding-left: 0;
}
.enter-details-modal .modal-title {
  margin-top: 7px;
  color: #fff;
  font-weight: bold;
}
.enter-details-modal .modal-content .modal-body {
  padding-top: 20px;
}
.custom-prescription-modal p,
.custom-prescription-modal span {
  color: #000 !important;
}
.custom-prescription-modal .form-control[disabled],
.custom-prescription-modal .form-control[readonly],
.custom-prescription-modal fieldset[disabled] .form-control {
  color: #000 !important;
}
.custom-prescription-modal li {
  color: #000;
}
.send-Prescription-modal.custom-prescription-modal .modal-content {
  background-color: #fff;
}
.custom-prescription-modal input,
.custom-prescription-modal input:focus {
  border: 1px solid gray;
}
.custom-prescription-modal label {
  color: #000d3c;
  font-weight: 600;
}
.custom-prescription-modal button.close:focus {
  outline: none;
}
.send-Prescription-modal.custom-prescription-modal .h4 {
  color: #000d3c;
  font-weight: bold;
}
.custom-prescription-modal input::placeholder {
  color: #000 !important;
}
.custom-prescription-modal strong {
  color: #000;
}
.custom-prescription-modal .ck.ck-editor {
  border: 1px solid gray;
}

.custom-prescription-modal .ck.ck-sticky-panel__content {
  border-bottom: 1px solid;
}
.custom-prescription-modal .ck.ck-icon.ck-icon_inherit-color,
.custom-prescription-modal .ck.ck-icon.ck-icon_inherit-color * {
  color: #000;
}
.custom-upload-img {
  max-width: 225px;
  width: 100%;
}
/**/
.input-file-btn-holder {
  position: relative;
  width: 148px;
  height: 44px;
  margin: 0 auto 5px;
  border-radius: 10px;
  overflow: hidden;
}
.input-file-btn-holder input {
  width: 100%;
  height: 100%;
  display: block;
  font-size: 0;
  opacity: 0;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.input-file-btn-holder .input-file-btn {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #003b6e;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.card.card-login {
  background: rgba(0, 59, 110, 0.8);
  border-radius: 30px;
}

/*page not found*/
.page-not-found {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}
.page-not-found .container-fluid {
  padding: 0px 90px;
}
.page-not-found .reset-header {
  padding: 40px 0px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
.page-not-found .reset-header .logo {
  width: 173px;
  display: block;
}
.page-not-found .not-found-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-not-found .not-found-section .not-found-img {
  width: 200px;
  margin: 0 auto 50px;
}
.page-not-found .not-found-text h2 {
  font-size: 110px;
  line-height: 120px;
  font-weight: bold;
  letter-spacing: 5px;
  color: #fd6f21;
}
.page-not-found .not-found-text h3 {
  font-size: 56px;
  line-height: 71px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
}

.react-datatable {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/* .rdt_TableHeader {border-top-left-radius: 10px;border-top-right-radius: 10px;} */
.react-datatable .table-actions-btns .btn {
  transition: all 150ms ease-in;
  border: 1px solid #000 !important;
  margin: 0 5px 0 0;
}
.react-datatable .table-actions-btns .btn:hover {
  color: #000;
}
.react-datatable .btn-info.btn-link:hover {
  background: #0d6efd !important;
  border-color: #0d6efd !important;
  color: #fff;
}
.react-datatable .btn-success.btn-link:hover {
  background: #87cb16 !important;
  border-color: #87cb16 !important;
  color: #fff;
}
.react-datatable .btn-danger.btn-link:hover {
  background: #fb404b !important;
  border-color: #fb404b !important;
  color: #fff;
}
/* background: rgba(93,203,198,.7) !important;border-color: rgba(93,203,198,.7) !important; */

.react-datatable .btn-info.btn-link {
  color: #000;
}
.react-datatable .btn-success.btn-link {
  color: #87cb16;
}
.react-datatable .btn-danger.btn-link {
  color: #fb404b;
}
.datatable-title.card {
  border-radius: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 16px 30px;
  border: 0;
  background-color: rgba(0, 59, 110, 0.8);
}
.react-datatable .td-name {
  color: #000;
}
.react-datatable .rdt_Table {
  border: 1px solid #dfe0eb;
  border-top: 0;
}
.react-datatable .rdt_TableHead .rdt_TableHeadRow {
  padding: 0 14px;
  font-size: 15px;
}
.react-datatable .rdt_TableBody .rdt_TableRow {
  padding: 0 14px;
}
.react-datatable .rdt_TableBody .rdt_TableRow:last-child {
  border-bottom: 0;
}
.care-md-breadcrumb .breadcrumb {
  background-color: transparent;
}
.care-md-breadcrumb .breadcrumb a.breadcrumb-item {
  color: #00beff;
}
.care-md-breadcrumb .breadcrumb a.breadcrumb-item:hover {
  text-decoration: underline;
}
nav.rdt_Pagination {
  border: 1px solid #dfe0eb;
  border-top: 0;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #000;
}
.Toastify__progress-bar--error {
  background: #00beff !important;
}
.create-form .panel-body {
  padding: 20px;
  border: 1px solid rgba(93, 203, 198, 0.2);
  background: rgba(0, 59, 110, 0.8);
}
.create-form .panel-body .pvr-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  padding-bottom: 7px;
  color: #fff;
}
.create-form .panel-body .pvr-header::after {
  content: "";
  background-color: #e41f36;
  width: 3rem;
  height: 2px;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
}
.checkbox {
  position: relative;
}
.checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  cursor: pointer;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.chip {
  background: rgba(0, 59, 110, 0.8) !important;
}
.highlightOption {
  background: rgba(0, 59, 110, 0.8) !important;
  color: #fff;
}
.multiSelectContainer li:hover {
  background: rgba(0, 59, 110, 0.8) !important;
}
.checkbox input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}
.checkbox label {
  padding-left: 25px;
  color: #fff;
}
.general-fields-checkboxes .checkboxes {
  padding-left: 20px;
  margin-top: 20px;
}
.checkboxes .checkbox {
  width: 33.3%;
  margin-bottom: 5px;
}
.react-form-builder .react-form-builder-preview {
  min-height: 570px !important;
  background: #fff !important;
}
#fb-editor .form-actions.btn-group {
  display: none;
}
.rdt_Pagination #pagination-next-page,
.rdt_Pagination #pagination-last-page,
.rdt_Pagination #pagination-previous-page,
.rdt_Pagination #pagination-first-page {
  color: #000;
  fill: #000;
}
.rdt_Pagination button:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  fill: rgba(0, 0, 0, 0.3) !important;
}
.rdt_Pagination svg {
  top: 7px !important;
}
.show-password-field input {
  padding-right: 33px;
}
.main-dashboard .card.card-stats h2.card-title {
  font-size: 40px;
  line-height: 45px;
}
.del-sweet-alert .btn {
  background-color: transparent;
  border: 0;
}
.del-sweet-alert .btn-danger {
  background-color: #fb404b;
  border: 1px solid #fb404b;
}
.del-sweet-alert .btn-danger:hover {
  background-color: rgb(212, 63, 58);
  border: 1px solid rgb(212, 63, 58);
  color: #fff;
}
.del-sweet-alert .btn-light {
  background-color: #cfdce7;
  border: 1px solid #cfdce7;
  color: #888888;
}
.del-sweet-alert .btn-light:hover {
  background-color: #dae0e5;
  border: 1px solid #dae0e5;
  color: #888888;
}

/* dashboard-consultation */
.dashboard-consultation-wrapper .consultation-heading h2 {
  font-size: 24px;
  font-weight: bold;
}
.dashboard-consultation-wrapper .consultation-heading p {
  font-size: 18px;
}
.dashboard-consultation-wrapper .table.consultation-table tr th {
  font-size: 15px;
  color: #000;
  font-weight: 600;
}
.dashboard-consultation-wrapper .table-heading {
  border-bottom: 1px solid #cfcfcfcf;
  width: 100%;
}
.dashboard-consultation-wrapper .table-heading::after {
  content: "";
  background-color: #e41f36;
  width: 3rem;
  height: 2px;
  border-radius: 2px;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
}
.send-Prescription-modal .modal-dialog {
  max-width: 50%;
  margin: 0 auto;
}

.patient-status-btn .btn {
  border-color: #000;
  background-color: transparent;
  color: #000;
  width: 100px;
}

.patient-status-btn .dropdown-toggle::after {
  color: #000 !important;
}
.patient-status-btn .dropdown-menu.show {
  box-shadow: 0 0 10px rgb(0 0 0 / 70%);
}
.send-Prescription-modal .modal-content {
  background: #fff;
  box-shadow: 0px 0px 10px #000;
}
.table-bigboy > tbody > tr > td {
  color: #000;
  white-space: inherit;
}
.table tbody .td-name,
.table .td-status,
.table .td-start,
.table .td-name,
.table .td-actions,
.table > thead > tr > th {
  color: #000;
}
.rc-pagination {
  justify-content: flex-end;
}
.table-full-width {
  border: 1px solid rgb(223, 224, 235);
  margin: 0;
  border-top: 0;
  padding: 0 !important;
  overflow-y: auto;
}
.table-bigboy tr td,
.table-bigboy tr th {
  border-bottom: 1px solid rgb(223, 224, 235) !important;
}
.table-bigboy .td-start,
.table-bigboy .serial-col {
  padding-left: 30px;
}
.table .btn-success.view {
  border: 1px solid #000 !important;
}
.table .btn-success.view:hover {
  background-color: #87cb16 !important;
  border-color: #87cb16 !important;
  color: #fff;
}
.show-password-field i {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  color: #fff;
}
.fa-money-bill:before {
  font-size: 16px;
}
.rotate180 {
  transform: rotate(180deg) !important;
}
.custom-table-pagination .cursor {
  cursor: pointer;
}
.custom-table-pagination .cursor .arrow {
  display: none;
}
.custom-table-pagination .cursor:hover {
  opacity: 0.7;
}
.custom-table-pagination .cursor:hover .arrow {
  display: block;
}
.rdt_TableHead {
  text-transform: uppercase;
}
/* .rdt_TableBody .rdt_TableCell div:first-child {white-space: inherit;} */
.w150 {
  width: 150px !important;
}
.w120 {
  width: 120px !important;
}
.w180 {
  width: 180px !important;
}
.w200 {
  width: 200px !important;
}
.w230 {
  width: 230px !important;
}

.roles-modal-body {
  height: calc(100vh - 320px);
  overflow: hidden;
  overflow-y: auto;
}
.roles-management {
  padding: 12px;
  background-color: #e7e3e34a;
  margin-bottom: 16px;
}

[type="file"] {
  color: #fff;
}
/* promo code css  */
.search_users label {
  margin-right: 24px;
  margin-left: 22px;
}
.ml-0 label {
  margin-left: 0;
}
.searchBox::placeholder {
  color: white;
}
.searchBox {
  color: white;
}
.search_bounce input {
  background: transparent;
  color: white;
  border: 1px solid white;
  margin-left: 14px;
  border-radius: 6px;
  padding: 10px 18px;
}
.search_bounce input::placeholder {
  color: white;
}
.consult_form_content {
  height: 300px;
  background: rgba(0, 59, 110, 1);
  width: 300px;
  overflow: auto;
  padding: 20px;
  border-radius: 19px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
}
.consult_wrap {
  display: flex;
  align-items: baseline;
  flex-direction: row-reverse;
  justify-content: flex-end;
  color: white;
}
.consult_wrap input {
  margin-right: 10px;
}
.items-center {
  align-items: center;
}
.custom-send-btn {
  padding-left: 15px !important;
  padding-right: 0 !important;
}
.custom-set-spacing {
  padding: 8px 5px;
  font-size: 14px;
}
.form-controll {
  background: rgba(0, 59, 110, 0.8);
  border: none;
  color: #fff;
  padding: 6px 17px;
  border-radius: 8px;
}
.date-picker-container.form-controll
  .react-datepicker__view-calendar-icon
  input {
  padding: 6px 10px 5px 5px;
  background: transparent;
  border: none;
  color: white;
  max-width: 150px;
  width: 100%;
}
.date-picker-container.form-controll
  .react-datepicker__input-container
  .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
  right: 0;
}

.filter-field input {
  border: 1px solid black;
  color: black;
}
.filter-field input:focus {
  border: 1px solid black;
  color: black !important;
}
.bg_blue {
  background-color: #003b6e;
}
.filter-field input {
  padding: 10px;
  border-radius: 8px;
}
.form-wrap.form-builder .frmb .form-elements [contenteditable].form-control,
.form-wrap.form-builder .frmb .form-elements input[type="text"],
.form-wrap.form-builder .frmb .form-elements input[type="number"],
.form-wrap.form-builder .frmb .form-elements input[type="date"],
.form-wrap.form-builder .frmb .form-elements input[type="color"],
.form-wrap.form-builder .frmb .form-elements textarea,
.form-wrap.form-builder .frmb .form-elements select {
  color: #000 !important;
}
.form-wrap.form-builder .form-control:focus {
  color: #000 !important;
}
.form-wrap.form-builder .form-control,
.form-wrap.form-builder .form-control::placeholder {
  color: #000 !important;
}
.white-them {
  color: #fff;
}
a.label-warning {
  background-color: #393c48;
  padding: 2px 7px;
  color: #fff;
}
a.label-success {
  background-color: #87cb16;
}
a.label-danger {
  background-color: #fb404b;
}

.card-login.custom-login-card.card {
  padding-bottom: 15px;
}
.w_94 {
  width: 94%;
}
.custom-on-switch.custom-switch .custom-control-label::before {
  content: "OFF";
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 7px;
  font-size: 13px;
}
.custom-on-switch
  .custom-control-input:checked
  ~ .custom-control-label::before {
  content: "ON";
  display: flex;
  align-items: center;
  padding-left: 10px;
  justify-content: start;
  font-size: 13px;
}
.switch-modal-wraper .h4,
.switch-modal-wraper p {
  color: #fff;
}
.custom-qr-code-wraper canvas {
  height: 169px !important;
  width: 169px !important;
  background: #fff;
  padding: 10px;
}
.password-mangement-fieldmin-width .react-datatable div[data-column-id="2"] {
  min-width: 70px;
  max-width: 200px;
}
.btn-success.disabled,
.btn-success:disabled {
  background-color: #87cb16;
  cursor: not-allowed !important;
  pointer-events: all !important;
}
.btn-success.disabled:hover {
  background-color: #87cb16;
  color: #fff !important;
}

.password-mangement-fieldmin-width .react-datatable div[data-column-id="3"] {
  min-width: 70px;
  max-width: 400px;
}
.password-mangement-fieldmin-width .react-datatable div[data-column-id="4"] {
  min-width: 240px;
  max-width: 400px;
}
.password-mangement-fieldmin-width .react-datatable div[data-column-id="1"] {
  min-width: 100px;
  max-width: 400px;
}
.password-mangement-fieldmin-width .react-datatable div[data-column-id="5"] {
  min-width: 100px;
  max-width: 200px;
}
.job-form-admin-table .react-datatable div[data-column-id="1"] {
  min-width: 160px;
}
.job-form-admin-table .react-datatable div[data-column-id="7"] {
  min-width: 160px;
}
.site-setting-tablz .react-datatable div[data-column-id] {
  min-width: 100px;
  max-width: 500px;
}
.error-page h1 {
  font-size: 100px;
  font-weight: bold;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 5px;
  color: #003b6e;
}
.error-page h2 {
  margin-bottom: 0;
  color: #003b6e;
  font-weight: 500;
  margin-top: 0;
  text-align: center;
  font-size: 24px;
}
.error-page span {
  color: #00beff;
}
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.error-wraper {
  max-width: 500px;
  width: 100%;
  padding: 28px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin: 0 20px;
}
.promo-code-dates::before {
  display: none !important;
}
.pagination select {
  padding-top: 4px;
  margin-left: 10px;
  padding-bottom: 4px;
}
.pagination button {
  background: transparent;
  border: 1px solid #c5c2c2;
  padding-left: 14px;
  padding-right: 14px;
}
.pagination .active {
  background-color: #003b6e;
  color: white;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: end;
  margin-top: 15px;
  flex-wrap: wrap;
  align-items: center;
}
.total_pages {
  padding: 0 10px;
}
.desktop_none {
  display: none;
}
.custom-collapse-header {
  background: rgba(0, 59, 110, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 14px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}
.custom-collapse-header i {
  margin-right: 16px;
}
.custom-collapse {
  overflow: hidden;
}

.custom-collapse .custom-collapse-header {
  cursor: pointer;
}

.custom-collapse .custom-collapse-body {
  transition: max-height 0.3s ease;
}

.custom-collapse.open .custom-collapse-body {
  max-height: 1000px; /* Set the maximum height based on your content */
}

.collapse-content {
  transition: opacity 0.3s ease;
  opacity: 0;
}

.collapse-content.open {
  opacity: 1;
}
.custom-collapse-header span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .medical-consultation-table #cell-8-undefined{
    min-width:212px;
} */
/* media quriessssssss */
/* responsive */
@media only screen and (max-width: 1399px) {
  .page-not-found .not-found-section .not-found-img {
    width: 130px;
    margin: 0 auto 25px;
  }
  .page-not-found .not-found-text h2 {
    font-size: 80px;
    line-height: 88px;
  }
  .page-not-found .not-found-text h3 {
    font-size: 38px;
    line-height: 40px;
  }
  .main-dashboard .card.card-stats h2.card-title {
    font-size: 30px;
    line-height: 35px;
  }
  .site-setting-tablz .react-datatable div[data-column-id] {
    min-width: 100px;
    max-width: 250px;
  }
  .blog-admin-tablelayout .react-datatable div[data-column-id] {
    min-width: 90px;
    max-width: 400px;
  }
  .forms-admin-tablelayout .react-datatable div[data-column-id] {
    max-width: 214px;
  }
  .forms-admin-tablelayout .react-datatable div[data-column-id="3"] {
    max-width: 120px;
    min-width: 70px !important;
  }
  .forms-admin-tablelayout .react-datatable div[data-column-id="4"] {
    min-width: 214px !important;
  }
  .main-dashboard canvas {
    min-height: 271px !important;
    max-width: 500px !important;
    width: 100% !important;
    margin: auto;
    object-fit: contain;
    height: 400px !important;
  }
}
@media only screen and (max-width: 991px) {
  .page-not-found .reset-header {
    padding: 0;
    position: relative;
    height: 90px;
    display: flex;
    align-items: center;
  }
  .page-not-found .not-found-section {
    height: calc(100% - 90px);
  }
  /**/
  .page-not-found .container-fluid {
    padding: 0px 30px;
  }
  .del-sweet-alert h2 {
    font-size: 28px !important;
  }
}
@media only screen and (max-width: 767px) {
  .page-not-found .not-found-section .not-found-img {
    width: 100px;
    margin: 0 auto 25px;
  }
  .main-dashboard .card.card-stats h2.card-title {
    font-size: 24px;
    line-height: 29px;
  }
}
@media only screen and (max-width: 479px) {
  .page-not-found .not-found-text h2 {
    font-size: 55px;
    line-height: 60px;
  }
  .page-not-found .not-found-text h3 {
    font-size: 28px;
    line-height: 30px;
  }
  /**/
  .page-not-found .reset-header .logo {
    width: 140px;
  }
  .blog-category-admin-tablelayout .react-datatable div[data-column-id="2"] {
    min-width: 100px !important;
  }
}

/**/
.Toastify__toast-theme--light {
  background: #fff !important;
}
/* .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter svg{
    fill: #00BEFF !important;
} */
.Toastify__progress-bar--success {
  /* background: #00BEFF !important; */
}
.Toastify__toast-body {
  color: #000 !important;
}
.Toastify__close-button--light {
  color: #fff !important;
  opacity: 0.6 !important;
}
.btn.btn-icon {
  padding: 8px 8px;
}
.modal-footer .btn-filled {
  padding: 10px 22px !important;
}

.password-mangement-fieldmin-width .react-datatable .btn {
  margin-bottom: 0;
}

/**/
/*media queires*/
@media (max-width: 1919px) {
  .users-table .td-name {
    width: 230px;
  }

  .users-table .td-email {
    width: 240px;
  }
}

@media (max-width: 1800px) {
  .filter-btns-holder button {
    width: 50%;
  }
  .promo-filter-cards .react-datatable div[data-column-id] {
    width: 200px;
  }
  /* .password-mangement-fieldmin-width .react-datatable div[data-column-id] {
    min-width: 180px;
  } */
}
@media (max-width: 1480px) {
  .genertic-customform-width .react-datatable div[data-column-id] {
    min-width: 170px;
  }
  .precriptionfill-admin-tablelayout .react-datatable div[data-column-id] {
    min-width: 101px;
    max-width: 153px;
  }
  .precriptionfill-admin-tablelayout .react-datatable div[data-column-id="5"] {
    min-width: 218px;
  }

  .cms-screen-admin-layout .react-datatable div[data-column-id="4"] {
    min-width: 100px;
    max-width: 150px;
  }
}

@media (max-width: 1365px) {
  .dshbrd-user-table .td-kyc-col {
    width: 140px;
  }

  .dshbrd-user-table .td-created-col,
  .users-table .td-created-col,
  .newsletter-table .td-created-col {
    width: 170px;
  }
}

@media (max-width: 1199px) {
  .text-truncate {
    width: 150px !important;
  }

  .filter-btns-holder button {
    width: 100%;
  }

  .card-user.table-big-boy.card {
    min-height: auto;
  }
  .profile-info-order {
    order: 2;
    margin-top: 20px;
  }
  .medical-type-admin-tablelayout .react-datatable div[data-column-id="2"] {
    min-width: 100px;
    max-width: 150px;
  }
}

@media (max-width: 991px) {
  .nav-icon-holder {
    display: block !important;
  }

  .table .serial-col {
    width: 30px;
  }

  /* i.nc-icons{height: 100%;display: block;} */
  span.nav-icon-holder {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .nav-icon-holder.cross-icon {
    position: absolute;
    top: -10px;
    right: 20px;
  }
  .main-panel {
    width: 100% !important;
  }

  .sidebar {
    right: 0px !important;
    left: 0;
    -webkit-transform: translate3d(-290px, 0, 0);
    transform: translate3d(-290px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .nav-open .sidebar {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .filter-btns-holder {
    justify-content: center;
  }

  /* .filter-btns-holder button {width: auto;margin: 0px 15px;} */
  .btn-filled {
    padding: 7px 22px !important;
  }

  .outline-button {
    padding: 7px 22px;
  }

  .filter-btns-holder button {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .check-inline .fancy-checkbox {
    width: 48%;
  }

  .filter-btns-holder button {
    width: 100% !important;
  }

  .text-truncate {
    width: 110px !important;
  }

  .contract-border.nft-detail-holder.d-flex {
    width: 469px !important;
  }

  span.text-white.text-colum {
    width: 274px;
  }

  .url-links {
    display: block !important;
  }

  .nft-detail-holder {
    margin-bottom: 13px;
  }

  .nft-detail-holder.url {
    display: flex;
    justify-content: center;
  }
  .table-head .card-title {
    margin: 0 0 20px;
  }
  .pagination button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 575px) {
  .check-inline .fancy-checkbox {
    width: 100%;
    margin-right: 0;
  }
  .rdrDateRangePickerWrapper {
    flex-wrap: wrap;
  }
  .rdrDefinedRangesWrapper {
    width: 332px;
  }
  /* .table .permissions-pills { white-space: nowrap;} */
  span.text-white.text-colum {
    width: 231px;
  }
  .table-head {
    flex-direction: column;
    text-align: center;
  }
  nav.navbar.navbar-expand-lg.navbar-light {
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-dashboard .card.card-stats h2.card-title {
    font-size: 22px;
    line-height: 26px;
  }
  .del-sweet-alert h2 {
    font-size: 24px !important;
  }
  .del-sweet-alert .btn-light,
  .del-sweet-alert .btn-danger {
    padding: 8px 20px;
  }
  .send-Prescription-modal .modal-dialog {
    max-width: 90%;
  }
  #main-panel .content {
    padding: 30px 20px !important;
  }
  .roles-listing-wrap div[data-column-id] {
    min-width: 130px;
  }
  .datatable-title.card {
    padding: 16px 14px;
  }
  .btn-filled {
    padding: 7px 11px !important;
    font-size: 13px;
  }
  .datapiker-custom-alignment {
    align-items: end;
  }
  .filter-card .table-head {
    align-items: start !important;
  }
  .filter-card .table-head h4 {
    font-size: 18px;
    margin-bottom: 3px;
  }
  .pagination button {
    padding-left: 11px;
    padding-right: 11px;
  }
  .mobile_block {
    display: block;
  }
  .mobile_none {
    display: none;
  }
  custom-collapse.desktop_none.mobile_block {
    display: block !important;
  }
}

@media (max-width: 479px) {
  .contract-border.nft-detail-holder.d-flex {
    width: 100%;
    flex-direction: column;
  }

  .filter-btns-holder {
    flex-direction: column;
  }

  .filter-btns-holder button {
    margin: 10px 0px;
    width: 100%;
  }

  .check-inline {
    flex-wrap: wrap;
  }

  .nft-detail-holder li {
    display: flex;
    flex-direction: column;
  }

  .collection-detail {
    flex-direction: column-reverse;
  }

  .collection-detail .collection-detail-holder {
    flex-direction: column;
  }

  .card .card-title {
    font-size: 14px;
  }
  .custom-collapse-header {
    font-size: 14px;
  }
  /**/
  .card.filter-card button {
    margin: 10px 0px !important;
  }
  /**/
  .btn-filled,
  .btn-filled:focus,
  .outline-button:active {
    font-size: 16px;
  }
  /**/
  .calender-button .btn-filled {
    width: 100%;
  }
  .datapiker-custom-alignment {
    align-items: end;
  }
  .datapiker-custom-alignment .filter-btns-holder button {
    margin-bottom: 0 !important;
  }
  .w_94 {
    width: 94%;
  }
  .wi-100 {
    width: 100%;
  }
  .btn-filled {
    font-size: 11px !important;
    padding: 7px 5px !important;
  }
  .main-dashboard canvas {
    min-height: auto !important;
    height: auto !important;
    margin-bottom: 20px;
  }
  #main-panel .content {
    padding: 0px 10px !important;
  }
}

@media (max-width: 319px) {
  button.float-sm-right.mb-0.btn.btn-info {
    font-size: 13px;
    padding: 9px;
  }

  span.text-white.text-colum {
    width: 190px;
  }

  .nft-detail-holder.url.text.d-flex {
    flex-direction: column;
  }

  strong.mr-2.text-white {
    display: flex;
    justify-content: center;
  }

  span.collect-text {
    display: flex;
    justify-content: center;
  }

  .btn-filled {
    padding: 7px 16px;
  }

  .outline-button {
    padding: 7px 16px;
  }
  /**/
  .btn-filled,
  .btn-filled:focus,
  .outline-button:active {
    font-size: 13px;
  }
}
.btn-light-blue {
  background-color: #003b6e;
  border-radius: 7px;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  border: 2px solid #003b6e;
  box-shadow: unset;
  padding: 9px 18px;
  text-decoration: none;
}
.btn-light-blue:active,
.btn-light-blue:focus,
.btn-light-blue:hover {
  background-color: #003b6e;
  color: #003b6e;
  border-color: #003b6e;
  box-shadow: unset !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff !important ;
  background-color: #0d6efd8a !important;
  margin-bottom: 0 !important;
  border-color: #0d6efd8a !important;
  opacity: 1;
}
.btn-primary.disabled:hover {
  color: #fff !important ;
  background-color: #0d6efd8a;
  border-color: #0d6efd8a;
}
.medical-custom-info .consultation-table th {
  max-width: 70%;
  width: 100%;
}

.generic-back-btn {
  position: absolute;
  top: 9px;
  left: 110px;
  margin: 0;
  padding: 8px 20px !important;
}

@media (max-width: 480px) {
  .generic-back-btn {
    left: 85px;
  }
}

/* .btn-light-blue:hover img{filter:invert(15%) sepia(24%) saturate(6978%) hue-rotate(190deg) brightness(96%) contrast(104%)} */
/**/
