.modal-header {
    border: 0 none;
}
.modal-content {
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
}
.modal-dialog {
    padding-top: 60px;
}
.modal-footer {
    border-top: 0 none;
    padding: 10px 10px;        
}
.modal-footer .modal-footer .btn-default.btn-simple{
    font-weight: 400;
}

.modal.fade .modal-dialog {
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
}
.modal.in .modal-dialog {
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
}
.modal-small{
    .modal-dialog{
        max-width: 350px;
    }
}
.modal-small{
    .divider{
        margin: 0 auto;
        display: block;
        width: 14px;
        position: relative;
        margin-top: 40px;
        margin-bottom: 30px;
        font-size: $font-paragraph;
    }
    .divider:after{
        position: absolute;
        content: "";
        right: -140px;
        top: 12px;
        height: 1px;
        width: 115px;
        background-color: $light-gray;
    }
    .divider:before{
        position: absolute;
        content: "";
        left: -140px;
        top: 12px;
        height: 1px;
        width: 115px;
        background-color: $light-gray;
    }
    .modal-footer{
        text-align: center;
    }
}
.social-area{
    text-align: center;
    
    .btn-social{
        margin: 0 10px;
    }
}
.modal-backdrop.in {
    opacity: 0.25;
}
select.form-select{
    background: transparent;
    color: #fff;
    padding: 8px 12px;
    border: 4px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #fff;
    font-size: 14px;
    height: 40px;
}



